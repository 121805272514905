<template>
 <NarBar></NarBar>
  <router-view/>
</template>

<script>
import NarBar from './components/NarBar.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
// import 'bootstrap-fileinput/css/fileinput.min.css'
// import 'bootstrap-fileinput/js/fileinput.min.js'
// import 'bootstrap-fileinput/js/locales/zh.js'

export default{
  components:{
    NarBar
  },
  setup(){
  }
}

</script>

<style>
body{
   background-image: url("../src/assets/backgrond.jpg");  
   background-size: cover;
}

</style>
