<template>
<CotentField>404 NOT FOUND</CotentField>
</template>

<script>
import CotentField from '../../components/ContentField.vue'

export default{
    components:{
        CotentField,
    }
}

</script>

<style>

</style>