<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header">
                        <span class="name">{{ $store.state.user.name }}</span>
                        &nbsp;
                        <span class="title" v-if="$store.state.user.role == 0">同学</span>
                        <span class="title" v-else-if="$store.state.user.role == 1">老师</span>
                        <span class="title" v-else>教务</span>
                        <span class="title">欢迎您！</span>
                    </div>
                    <div class="card-body">
                        <img :src="$store.state.user.photo" alt="" style="width: 100%;">
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header"> 
                        <div style="font-weight: bold;font-size: xxx-large;float: left;">通知</div>
                        <nav aria-label="Page navigation example" style="position: relative;top:35px;">
                            <ul class="pagination justify-content-end">
                                <li :class="'page-item ' + (current_page === 1 ? 'disabled' : '')">
                                    <a class="page-link" @click="to_page(-2)" href="#">前一页</a>
                                </li>
                                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number">
                                    <a class="page-link" href="#" @click="to_page(page.number)">{{ page.number }}</a>
                                </li>
                                <li :class="'page-item ' + (current_page === max_pages ? 'disabled' : '')">

                                    <a class="page-link" href="#" @click="to_page(-1)">后一页</a>
                                </li>
                            </ul>
                        </nav>  
                    </div>
                    <div class="card-body">
                        <div class="accordion" id="noticeRoot" v-for="record in records" :key="record.id">
                            <div class="accordion-item" :id="'item ' + record.id">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        :data-bs-target="'#item' + record.id" :aria-controls="'item' + record.id">
                                        <span
                                            v-if="record.receiver === $store.state.user.name || record.receiver === $store.state.user.classname">你于{{
                                                record.createdTime }}接收到了由{{ record.sender }}发送的通知</span>
                                        <span v-else>你于{{ record.createdTime }}发送给{{ record.receiver }}一条通知</span>
                                    </button>
                                </h2>

                                <div :id="'item' + record.id" class="accordion-collapse collapse"
                                    data-bs-parent="#noticeRoot">
                                    <div class="accordion-body">
                                        {{ record.content }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import { ref, reactive } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap'

export default {
    setup() {
        const store = useStore();

        // 列表信息
        let records = ref([]);
        let creatorId = ref('');

        // 分页信息
        let current_page = ref(''); // 当前页
        let total_records = 0; // 总记录数
        let pages = ref([]); // 页面按钮显示情况 前几页是否存在 后几页页是否存在
        let max_pages = ref('')
        //添加框所用到的信息
        const addInfo = reactive({
            id: "",
            sender: "",
            receiver: "",
            content: "",
            createdTime: "",
        })

        const getAllInfo = (page) => {
            current_page.value = page;
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/getAllInfo",
                type: "get",
                data: {
                    page,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(resp);
                    records.value = resp.records;
                    total_records = resp.records_count;
                    max_pages.value = parseInt(Math.ceil(total_records / 8)); // 实时更新页面的最大值z
                    update_pages();
                },
            })
        };
        getAllInfo(1);


        // 更新页面显示按钮
        const update_pages = () => {
            let new_pages = []; // 更新按钮数
            for (let i = current_page.value - 2; i <= current_page.value + 2; i++) {
                if (i >= 1 && i <= max_pages.value) {
                    new_pages.push({
                        number: i,  // 页面编号
                        is_active: i === current_page.value ? "active" : "",   // 是否被激活
                    }); // 最多将当前页的前两页和后两页显示
                }
            }

            pages.value = new_pages;
        }
        // 展示提示信息
        const show_modal = (id) => {
            var myModal = new Modal(document.getElementById(id));
            myModal.show();
            setTimeout(() => {
                myModal.hide();
            }, 1000);
        }

        // 跳转页面

        const to_page = (page) => {
            if (page === -2) page = current_page.value - 1;
            else if (page === -1) page = current_page.value + 1;
            if (page >= 1 && page <= max_pages.value) {
                getAllInfo(page)
            }
        }

        const addOneInfo = () => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/outline/addOneInfo",
                type: "post",
                data: {
                    className: addInfo.className,
                    courseName: addInfo.courseName,
                    content: addInfo.content,
                    scores: addInfo.scores,
                    times: addInfo.times,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开添加失败模态框
                        show_modal('add-error');
                    } else {
                        Modal.getInstance("#add-info-bnt").hide();
                        // 打开添加成功模态框
                        show_modal('add-success');
                    }
                    getAllInfo(1);

                },
            })
        };
        const removeOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/outline/deleteOne",
                type: "post",
                data: {
                    id: object.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭删除模态框
                    Modal.getInstance(`#delete-info-modal-` + object.id).hide();
                    if (resp.error_message === "success") {
                        // 成功提示删除成功
                        show_modal('delete-success');
                    }
                    getAllInfo(1);
                }
            })
        };
        const updateOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/outline/updateOne",
                type: "post",
                data: {
                    id: object.id,
                    className: object.className,
                    courseName: object.courseName,
                    content: object.content,
                    scores: object.scores,
                    times: object.times,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭修改模态框
                    Modal.getInstance(`#update-info-modal-` + object.id).hide();
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开修改失败模态框
                        show_modal('update-error');
                    } else {
                        // 打开修改成功模态框
                        show_modal('update-success')
                    }
                    getAllInfo(1);
                },
            })
        };
        const refreshAddInfo = () => {
            addInfo.courseName = "";
            addInfo.content = "";
            addInfo.scores = "";
            addInfo.times = "";
            addInfo.className = "";
        };
        return {
            records,
            creatorId,
            addInfo,
            current_page,
            total_records,
            pages,
            max_pages,
            addOneInfo,
            removeOneInfo,
            updateOneInfo,
            refreshAddInfo,
            update_pages,
            to_page,
            show_modal,
        }
    }
}

</script>
        
<style scoped>
.name {
    font-weight: bold;
    font-size: 200%;
}

;

.title {
    font-weight: bold;
    font-size: x-large;
}</style>