<template>
  <div class="container">
    <div class="row">
      <div class="col-9">
        <div
          class="card shadow p-3 mb-5 bg-body-tertiary rounded"
          style="margin-top: 20px"
        >
          <div class="card-body">
            <div id="carouselExampleIndicators" class="carousel slide">
              <div class="carousel-inner carousel-fade" data-bs-ride="carousel">
                <div class="carousel-item active" data-bs-interval="1500">
                  <a href="https://jwc.mju.edu.cn/"
                    ><img
                      src="../../../assets/mjuBeautifulScenery.jpg"
                      class="d-block w-20"
                      alt="..."
                  /></a>
                </div>
                <div class="carousel-item" data-bs-interval="1500">
                  <a href="https://www.mju.edu.cn/main.htm"
                    ><img
                      src="../../../assets/mjuBeautifulScenery1.jpg"
                      class="d-block w-20"
                      alt="..."
                  /></a>
                </div>
                <div class="carousel-item" data-bs-interval="1500">
                  <a href="https://lib.mju.edu.cn/main.htm"
                    ><img
                      src="../../../assets/mjuBeautifulScenery2.jpg"
                      class="d-block w-20"
                      alt="..."
                  /></a>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" style="margin-top: 20px">
          <div class="card-body">
            <form @submit.prevent="register">
              <p class="fs-2 ustify-content-center">注册</p>
              <div class="mb-3">
                <label for="username" class="form-label fs-6"
                  >学号或教职工工号</label
                >
                <input
                  v-model="username"
                  type="number"
                  class="form-control"
                  id="username"
                  placeholder="请输入学号或教职工工号"
                />
              </div>
              <div class="mb-3">
                <label for="name" class="form-label fs-6">姓名</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="请输入姓名"
                />
              </div>
              <div class="mb-3">
                <label for="password" class="form-label fs-6">密码</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="请输入密码"
                />
              </div>
              <div class="mb-3">
                <label for="confirmedpassword" class="form-label fs-6"
                  >再次输入密码</label
                >
                <input
                  v-model="confirmedpassword"
                  type="password"
                  class="form-control"
                  id="confirmedpassword"
                  placeholder="请再次输入密码"
                />
              </div>
              <div class="mb-3">
                <label for="role" class="form-label fs-6">请选择角色</label>
                <select
                  id="role"
                  v-model="role"
                  class="form-select"
                  aria-label="Default select example"
                  @change.prevent="show_class()"
                >
                  <option value="0">学生</option>
                  <option value="1">教师</option>
                </select>
              </div>
              <div class="mb-3" id="aclass" style="display: none">
                <select v-model="className" class="form-select">
                  <option value="" selected>请选择所属班级</option>
                  <option
                    v-for="aclass in classes"
                    :key="aclass.id"
                    :value="aclass.className"
                  >
                    {{ aclass.className }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="sex" class="form-label fs-6">性别</label>
                <div class="form-check" style="display: float: left;;">
                  <input
                    v-model="sex"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="0"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    女
                  </label>
                </div>
                <div class="form-check" style="display: float: left;">
                  <input
                    v-model="sex"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="1"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    男
                  </label>
                </div>
              </div>
              <div class="error_message" style="color: red">
                {{ error_message }}
              </div>
              <button
                class="btn btn-info btn-lg"
                type="submit"
                style="width: 100%; margin-top: 15px"
              >
                注册
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 注册成功提示 -->
  <div
    class="modal fade"
    id="register-success"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-body">
        <div class="alert alert-success" role="alert">注册成功!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import $ from "jquery";
import { Modal } from "bootstrap/dist/js/bootstrap";

export default {
  setup() {
    let username = ref("");
    let password = ref("");
    let name = ref("");
    let confirmedpassword = ref("");
    let role = ref("");
    let sex = ref("");
    let error_message = ref("");
    let classes = ref("");
    let className = ref("");
    let store = useStore();

    const getInfo = () => {
      $.ajax({
        url: "https://erikblog.cn/api/user/account/getinfo",
        type: "get",
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          if (resp.error_message === "success") {
            console.log(resp);
            classes.value = resp.classes;
          }
        },
      });
    };
    getInfo();

    const show_class = () => {
      let select_class = document.getElementById("aclass");

      console.log(select_class);
      const infoRole = document.querySelector("#role option:checked").value;

      if (infoRole === "0") {
        select_class.style.display = "block";
      } else {
        select_class.style.display = "none";
      }
    };

    // 展示提示信息
    const show_modal = (id) => {
      var myModal = new Modal(document.getElementById(id));

      myModal.show();
      setTimeout(() => {
        myModal.hide();
      }, 1000);
    };

    const register = () => {
      $.ajax({
        url: "https://erikblog.cn/api/user/account/register",
        type: "post",
        data: {
          username: username.value,
          password: password.value,
          confirmed: confirmedpassword.value,
          name: name.value,
          role: role.value,
          sex: sex.value,
          className: className.value,
        },
        success(resp) {
          if (resp.error_message === "success") {
            show_modal("register-success");
          } else {
            error_message.value = resp.error_message;
          }
          refershInfo();
        },
      });
    };
    const refershInfo = () => {
      error_message.value = "";
      username.value = "";
      password.value = "";
      name.value = "";
      confirmedpassword.value = "";
      role.value = "";
      sex.value = "";
      className.value = "";
      let select_class = document.getElementById("aclass");
      select_class.style.display = "none";
    };

    return {
      username,
      password,
      name,
      confirmedpassword,
      role,
      sex,
      error_message,
      store,
      className,
      classes,
      register,
      show_class,
      show_modal,
      refershInfo,
    };
  },
};
</script>

<style scoped></style>
