<template>
  <div class="container">
    <div class="card" style="margin-top: 20px">
      <div class="card-header">
        <span style="font-size: xx-large">教学课件管理</span>
        <button
          class="btn btn-primary float-end"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#add-info-bnt"
        >
          添加
        </button>
      </div>

      <!-- 添加Modal -->
      <div
        class="modal fade"
        data-bs-backdrop="static"
        id="add-info-bnt"
        tabindex="-1"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-6">添加教学课件</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="refreshAddInfo"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="className" class="form-label"
                  >教学方案对应的课程名</label
                >
                <select v-model="addInfo.courseName" class="form-select">
                  <option value="" selected>请选择课程名</option>
                  <option
                    v-for="course in addInfo.plans"
                    :key="course.id"
                    :value="course.courseName"
                  >
                    {{ course.courseName }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="formFile" class="form-label">上传课件</label>
                <form @submit.prevent="upload">
                  <input
                    id="fileInput"
                    type="file"
                    name="file"
                    accept=".doc,.docx,.ppt,.pptx,.txt"
                  />
                  <button type="submit" class="btn btn-info">上传</button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="addOneInfo">
                确定
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                @click="refreshAddInfo"
              >
                取消
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加成功提示 -->
      <div class="modal fade" id="add-success" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-body">
            <div class="alert alert-success" role="alert">添加成功!</div>
          </div>
        </div>
      </div>
      <!-- 添加失败提示 -->
      <div class="modal fade" id="add-error" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              {{ addInfo.error_message }}
            </div>
          </div>
        </div>
      </div>
      <!-- 文件成功提示 -->
      <div
        class="modal fade"
        id="upload-success"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-body">
            <div class="alert alert-success" role="alert">文件上传成功!</div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">课程名</th>
              <th scope="col">课件名</th>
              <th scope="col">上传时间</th>
              <th scope="col">最近下载时间</th>
              <th scope="col">授课老师</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="object in records" :key="object.id">
              <td>{{ object.id }}</td>
              <td>{{ object.courseName }}</td>
              <td>{{ object.fileName }}</td>
              <td>{{ object.createdTime }}</td>
              <td>{{ object.modifiedTime }}</td>
              <td>{{ addInfo.teacher }}</td>
              <td>
                <div style="text-align: end">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    :data-bs-target="`#download-info-modal-` + object.id"
                  >
                    下载
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-toggle="modal"
                    :data-bs-target="`#delete-info-modal-` + object.id"
                    style="margin-left: 15px"
                  >
                    删除
                  </button>
                </div>
                <!-- 删除model -->
                <div
                  class="modal fade"
                  data-bs-backdrop="static"
                  :id="`delete-info-modal-` + object.id"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="delete">
                          删除教学方案
                        </h1>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">确定删除记录{{ object.id }}?</div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="removeOneInfo(object)"
                        >
                          确定
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          取消
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 下载成功提示 -->
                <div
                  class="modal fade"
                  id="download-success"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-body">
                      <div class="alert alert-success" role="alert">
                        下载成功!
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 删除成功提示 -->
                <div
                  class="modal fade"
                  id="delete-success"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-body">
                      <div class="alert alert-success" role="alert">
                        删除成功!
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 下载Modal -->
                <div
                  class="modal fade"
                  data-bs-backdrop="static"
                  :id="`download-info-modal-` + object.id"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-6">下载教学课件</h1>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div class="mb-3" style="color: red">
                          确定要下载{{ object.fileName }}吗?
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="downloadFile(object)"
                        >
                          确定
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          取消
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li :class="'page-item ' + (current_page === 1 ? 'disabled' : '')">
              <a class="page-link" @click="to_page(-2)" href="#">前一页</a>
            </li>
            <li
              :class="'page-item ' + page.is_active"
              v-for="page in pages"
              :key="page.number"
            >
              <a class="page-link" href="#" @click="to_page(page.number)">{{
                page.number
              }}</a>
            </li>
            <li
              :class="
                'page-item ' + (current_page === max_pages ? 'disabled' : '')
              "
            >
              <a class="page-link" href="#" @click="to_page(-1)">后一页</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap";

export default {
  setup() {
    const store = useStore();

    // 列表信息
    let records = ref([]);
    let creatorId = ref("");

    // 分页信息
    let current_page = ref(""); // 当前页
    let total_records = 0; // 总记录数
    let pages = ref([]); // 页面按钮显示情况 前几页是否存在 后几页页是否存在
    let max_pages = ref("");
    let fileUrl = ref(""); // 文件url
    //添加框所用到的信息
    const addInfo = reactive({
      id: "",
      courseName: "",
      fileName: "",
      createdTime: "",
      modifiedTime: "",
      tid: "",
      teacher: "",
      error_message: "",
      plans: [], // 供选择的教学方案课程
    });
    const getAllInfo = (page) => {
      current_page.value = page;
      $.ajax({
        url: "https://erikblog.cn/api/teaching/courseWare/getAllInfo",
        type: "get",
        data: {
          page: page,
          tid: localStorage.getItem("id"), // 用存在本地浏览器的id 刷新页面也不会消失
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          console.log(resp);
          records.value = resp.records;
          total_records = resp.records_count;
          max_pages.value = parseInt(Math.ceil(total_records / 8)); // 实时更新页面的最大值
          addInfo.plans = resp.plans;
          addInfo.teacher = store.state.user.name;
          update_pages();
        },
      });
    };
    getAllInfo(1);

    // 更新页面显示按钮
    const update_pages = () => {
      let new_pages = []; // 更新按钮数
      for (let i = current_page.value - 2; i <= current_page.value + 2; i++) {
        if (i >= 1 && i <= max_pages.value) {
          new_pages.push({
            number: i, // 页面编号
            is_active: i === current_page.value ? "active" : "", // 是否被激活
          }); // 最多将当前页的前两页和后两页显示
        }
      }

      pages.value = new_pages;
    };
    // 跳转页面

    const to_page = (page) => {
      if (page === -2) page = current_page.value - 1;
      else if (page === -1) page = current_page.value + 1;
      if (page >= 1 && page <= max_pages.value) {
        getAllInfo(page);
      }
    };

    // 展示提示信息
    const show_modal = (id) => {
      var myModal = new Modal(document.getElementById(id));
      myModal.show();
      setTimeout(() => {
        myModal.hide();
      }, 1000);
    };

    const addOneInfo = () => {
      $.ajax({
        url: "https://erikblog.cn/api/teaching/courseWare/addOneInfo",
        type: "post",
        data: {
          courseName: addInfo.courseName,
          fileName: addInfo.fileName,
          fileUrl: fileUrl.value,
          tid: store.state.user.id,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          if (resp.error_message !== "success") {
            addInfo.error_message = resp.error_message;
            // 打开添加失败模态框
            show_modal("add-error");
          } else {
            Modal.getInstance("#add-info-bnt").hide();
            // 打开添加成功模态框
            show_modal("add-success");
          }
          getAllInfo(1);
          refreshAddInfo();
        },
      });
    };
    const upload = () => {
      // 1.获得文件的input标签
      const fileInput = document.getElementById("fileInput");

      // 2.获得文件的对象
      const file = fileInput.files[0];

      if (!file) {
        addInfo.error_message = "请选择一个文件";
        show_modal("add-error");
        return;
      }
      addInfo.fileName = file.name; // 得到文件名

      // 3.将文件对象转化为可以传输的formData
      const formData = new FormData();
      formData.append("file", file);
      // 4.传文件给后端
      $.ajax({
        url: "https://erikblog.cn/api/teaching/courseWare/upload",
        type: "post",
        data: formData,
        processData: false, // 必须禁用JQuery处理数据
        contentType: false, // 必须禁用JQuery设置content-type
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          console.log(resp);
          addInfo.error_message = "";
          if (resp.error_message === "success") {
            show_modal("upload-success");
            fileUrl.value = resp.path; // 更新路径名
          } else {
            addInfo.error_message = resp.error_message;
          }
        },
        error(resp) {
          console.log(resp);
        },
      });
    };
    const removeOneInfo = (object) => {
      $.ajax({
        url: "https://erikblog.cn/api/teaching/courseWare/deleteOne",
        type: "post",
        data: {
          id: object.id,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          // 关闭删除模态框
          Modal.getInstance(`#delete-info-modal-` + object.id).hide();
          if (resp.error_message === "success") {
            // 成功提示删除成功
            show_modal("delete-success");
          }
          getAllInfo(1);
        },
      });
    };
    const downloadFile = (object) => {
      // 下载文件:浏览器地址为要下载文件url并按下回车
      const url = object.url; // 您要跳转的 文件URL
      window.location.href = url; // 跳转到指定链接进行下载

      // 更新下载时间
      $.ajax({
        url: "https://erikblog.cn/api/teaching/courseWare/updateOne",
        type: "post",
        data: {
          id: object.id,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          //关闭确认下载模态框
          Modal.getInstance(`#download-info-modal-` + object.id).hide();
          if (resp.error_message === "success") {
            // 成功提示下载成功
            show_modal("download-success");
          }
          getAllInfo(1);
        },
      });
    };
    const refreshAddInfo = () => {
      addInfo.courseName = "";
      addInfo.fileName = "";
      fileUrl.value = "";
    };
    return {
      records,
      creatorId,
      addInfo,
      current_page,
      total_records,
      pages,
      max_pages,
      addOneInfo,
      removeOneInfo,
      downloadFile,
      refreshAddInfo,
      update_pages,
      to_page,
      upload,
      show_modal,
    };
  },
};
</script>

<style scoped></style>
