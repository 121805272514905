<template>
    <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #7431fa;">
        <div class="container">
            <img src="../assets/mju.jpg" style="margin-right: 15px;">
            <a class="navbar-brand" href="#">教务管理系统</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'home_index' }">首页</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 2 ">
                        <router-link class="nav-link" active-class="active "
                            :to="{ name: 'training_index' }">培养方案管理</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 2 ">
                        <router-link class="nav-link" :to="{ name: 'user_account_register' }">注册账户</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 1">
                        <router-link class="nav-link" active-class="active"
                            :to="{ name: 'programme_index' }">教学大纲管理</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 1">
                        <router-link class="nav-link" active-class="active"
                            :to="{ name: 'plan_index' }">教案管理</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 1 || $store.state.user.role == 0">
                        <router-link class="nav-link" active-class="active"
                            :to="{ name: 'courseware_index' }">课件管理</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.state.user.role == 1 || $store.state.user.role == 0">
                        <router-link class="nav-link" active-class="active"
                            :to="{ name: 'homework_index' }">通知/上传作业管理</router-link>
                    </li> 
                </ul>
                <ul class="navbar-nav d-flex" v-if="$store.state.user.is_login">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{ $store.state.user.name }}
                        </a>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item" active-class="active"
                                    :to="{ name: 'myspace_index' }">个人主页</router-link></li>
                            <li><a class="dropdown-item" @click="logout" href="#">退出</a></li>
                        </ul>
                    </li>
                </ul>
                <ul class="navbar-nav" v-else>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'login_index' }">登录</router-link>
                    </li>
                </ul>
                <!-- <form class="d-flex" role="search">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> -->
            </div>
        </div>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    // 实时计算当前页面的路由名
    setup() {
        const route = useRoute();
        const store = useStore();
        let route_name = computed(() => route.name)
        const logout = () => {
            store.dispatch("logout");
        }

        return {
            route_name,
            logout,
        }
    }
}
</script>

<style></style>