<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card" style="margin-top: 20px">
          <div class="card-body">
            <form @submit.prevent="update_user_info">
              <p class="fs-2 text-center">修改个人信息</p>
              <div class="mb-3">
                <label for="name" class="form-label fs-6">姓名</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="请输入姓名"
                />
              </div>
              <div class="mb-3">
                <label for="oldPassword" class="form-label fs-6">旧密码</label>
                <input
                  v-model="oldPassword"
                  type="password"
                  class="form-control"
                  id="oldPassword"
                  placeholder="请输入旧密码"
                />
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label fs-6">新密码</label>
                <input
                  v-model="newPassword"
                  type="password"
                  class="form-control"
                  id="newPassword"
                  placeholder="请输入新密码"
                />
              </div>
              <div class="mb-3">
                <label for="confirmedPassword" class="form-label fs-6"
                  >再次输入新密码</label
                >
                <input
                  v-model="confirmedPassword"
                  type="password"
                  class="form-control"
                  id="confirmedPassword"
                  placeholder="请再次输入新密码"
                />
              </div>
              <div class="mb-3">
                <label for="formFile" class="form-label">更新个人头像</label>
                <form @submit.prevent="upload_photo">
                  <input
                    id="fileInput"
                    type="file"
                    name="file"
                    accept=".jpg,.png"
                  />
                  <button type="submit" class="btn btn-info">上传</button>
                </form>
              </div>
              <div class="mb-3">
                <label for="sex" class="form-label fs-6">性别</label>
                <div class="form-check">
                  <input
                    v-model="sex"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="0"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    女
                  </label>
                </div>
                <div class="form-check">
                  <input
                    v-model="sex"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="1"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    男
                  </label>
                </div>
              </div>
              <div class="error_message" style="color: red">
                {{ error_message }}
              </div>
              <button
                class="btn btn-info btn-lg"
                type="submit"
                style="width: 100%; margin-top: 15px"
              >
                确认修改
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 修改成功提示 -->
  <div class="modal fade" id="update-success" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-body">
        <div class="alert alert-success" role="alert">修改成功!</div>
      </div>
    </div>
  </div>
  <!-- 图片成功提示 -->
  <div class="modal fade" id="upload-success" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-body">
        <div class="alert alert-success" role="alert">图片上传成功!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { Modal } from "bootstrap/dist/js/bootstrap";
import $ from "jquery";

export default {
  setup() {
    let store = useStore();
    let name = ref("");
    let oldPassword = ref("");
    let newPassword = ref("");
    let confirmedPassword = ref("");
    let error_message = ref("");
    let photo = ref("");
    let sex = ref("");

    name.value = store.state.user.name;
    sex.value = store.state.user.sex;

    const show_modal = (id) => {
      var myModal = new Modal(document.getElementById(id));
      myModal.show();
      setTimeout(() => {
        myModal.hide();
      }, 1000);
    };

    const update_user_info = () => {
      $.ajax({
        url: "https://erikblog.cn/api/user/account/update",
        type: "post",
        data: {
          name: name.value,
          oldPassword: oldPassword.value,
          newPassword: newPassword.value,
          confirmedPassword: confirmedPassword.value,
          sex: sex.value,
          photo: photo.value,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          error_message.value = "";
          if (resp.error_message === "success") {
            show_modal("update-success");
            name.value = "";
            oldPassword.value = "";
            error_message.value = "";
            newPassword.value = "";
            confirmedPassword.value = "";
            sex.value = "";
          } else {
            error_message.value = resp.error_message;
          }
        },
      });
    };
    const upload_photo = () => {
      // 1.获得文件的input标签
      const fileInput = document.getElementById("fileInput");

      // 2.获得文件的对象
      const file = fileInput.files[0];
      if (!file) {
        error_message.value = "请选择一个文件";
        return;
      }

      // 3.将文件对象转化为可以传输的formData
      const formData = new FormData();
      formData.append("file", file);
      // 4.传文件给后端
      $.ajax({
        url: "https://erikblog.cn/api/user/account/upload",
        type: "post",
        data: formData,
        processData: false, // 必须禁用JQuery处理数据
        contentType: false, // 必须禁用JQuery设置content-type
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          error_message.value = "";
          if (resp.error_message === "success") {
            show_modal("upload-success");
            photo.value = resp.path;
          } else {
            error_message.value = resp.error_message;
          }
        },
        error() {
          // console.log(resp);
        },
      });
    };

    return {
      store,
      name,
      oldPassword,
      newPassword,
      sex,
      confirmedPassword,
      photo,
      error_message,
      upload_photo,
      update_user_info,
      show_modal,
    };
  },

  mounted() {},
};
</script>

<style scoped></style>
