<template>
    <div class="container">
        <div class="row">
            <div class="col-9">
                <div class="card shadow p-3 mb-5 bg-body-tertiary rounded" style="margin-top: 20px;">
                    <div class="card-body ">
                        <div id="carouselExampleIndicators" class="carousel slide">
                            <div class="carousel-inner carousel-fade" data-bs-ride="carousel">
                                <div class="carousel-item active" data-bs-interval="1500">
                                    <a href="https://jwc.mju.edu.cn/"><img src="../../../assets/mjuBeautifulScenery.jpg"
                                            class="d-block w-20" alt="..."></a>
                                </div>
                                <div class="carousel-item" data-bs-interval="1500">
                                    <a href="https://www.mju.edu.cn/main.htm"><img
                                            src="../../../assets/mjuBeautifulScenery1.jpg" class="d-block w-20"
                                            alt="..."></a>
                                </div>
                                <div class="carousel-item" data-bs-interval="1500">
                                    <a href="https://lib.mju.edu.cn/main.htm"><img
                                            src="../../../assets/mjuBeautifulScenery2.jpg" class="d-block w-20"
                                            alt="..."></a>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <form @submit.prevent="login">
                            <p class="fs-2 ustify-content-center">登录</p>
                            <div class="mb-3">
                                <label for="username" class="form-label fs-6">学号或教职工工号</label>
                                <input v-model="username" type="text" class="form-control" id="username"
                                    placeholder="请输入学号或教职工工号">
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label fs-6">密码</label>
                                <input v-model="password" type="password" class="form-control" id="password"
                                    placeholder="请输入密码">
                            </div>
                            <div class="error_message">{{ error_message }}</div>
                            <div class="d-grid gap-2">
                                <button class="btn btn-primary btn-lg login_button" type="submit">登录</button>
                                <!-- <button class="btn btn-info btn-lg login_button" @click="to_register()"
                                type="button">注册</button> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { useStore } from 'vuex' // useStore:用全局变量
import { ref } from 'vue'      // ref:局部变量
import router from '../../../router/index' // 使用router.push(name:"")实现页面跳转

export default {
    components: {
    },
    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');

        const login = () => {
            error_message.value = "";
            // dispatch(调用的函数名,参数)调用action里的函数
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    store.dispatch("getinfo", {
                        success() {
                            console.log(store.state.ModuleUser);
                            router.push({ name: "home_index" });
                        }
                    })
                },
                error() {
                    error_message.value = "用户名或密码错误";
                    username.value = "";
                    password.value = "";
                }
            })
        };
        const to_register = () => {
            router.push({ name: "user_account_register" })
        }
        return {
            username,
            password,
            error_message,
            login,
            to_register,
        }
    }
}


</script>
<style scoped>
.login_button {
    margin-top: 25px;
}

.error_message {
    color: red;
}
</style>