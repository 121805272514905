<template>
    <div class="container">
        <div class="card" style="margin-top: 20px;">
            <div class="card-header">
                <span style="font-size: xx-large;">通知/上传作业管理</span>
                <button class="btn btn-primary float-end" type="button" data-bs-toggle="modal"
                    data-bs-target="#add-info-bnt">添加</button>
            </div>

            <!-- 添加Modal -->
            <div class="modal fade" data-bs-backdrop="static" id="add-info-bnt" tabindex="-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-6">上传通知/作业</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="refreshAddInfo"></button>

                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="courseName" class="form-label">所属课程</label>
                                <select v-model="addInfo.courseName" class="form-select">
                                    <option value="">请选择所属课程</option>
                                    <option v-for="course in addInfo.plans" :key="course.id" :value="course.courseName">{{
                                        course.courseName }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="content" class="form-label">内容</label>
                                <textarea placeholder="请输入内容" class="form-control" id="trainingGoal" rows="8"
                                    v-model="addInfo.content" maxlength="1000"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="className" class="form-label">接收方</label>
                                <select v-model="addInfo.receiver" class="form-select">
                                    <option v-if="$store.state.user.role == 1" value="" selected>请选择接收方(学生/班级)</option>
                                    <option v-else value="" selected>请选择接收方(老师)</option>

                                    <option v-for=" receiver in addInfo.receivers" :key="receiver.id"
                                        :value="receiver.className === undefined ? receiver.name : receiver.className">
                                        <span v-if="receiver.className === undefined">{{ receiver.name }}</span>
                                        <span v-else>{{ receiver.className }}</span>
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="type" class="form-label fs-6">信息类型</label>
                                <!-- 使用事件对象（event object）来获取当前触发事件的select元素的id -->
                                <select id="type" v-model="addInfo.type" class="form-select"
                                    @change.prevent="show_upload_div($event)">
                                    <option value="0" selected>通知</option>
                                    <option value="1">作业文件</option>
                                </select>
                            </div>

                            <!-- 默认隐藏当信息类型为作业时显示 -->
                            <div class="mb-3" style="display: none;" id="uploadDiv">
                                <div for="formFile" class="form-label">上传作业(只支持zip和rar格式)</div>
                                <input id="fileInput" type="file" name="file" accept=".zip,.rar" />
                                <button type="submit" @click.prevent="upload(0)" class="btn btn-info">上传</button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click.prevent="addOneInfo">确定</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                @click.prevent="refreshAddInfo">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        
              <!-- 添加失败提示 -->
              <div class="modal fade" id="add-error" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert">
                            {{ addInfo.error_message }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 添加成功提示 -->
            <div class="modal fade" id="add-success" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-body">
                        <div class="alert alert-success" role="alert">
                            添加成功!
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">所属课程</th>
                            <th scope="col">内容</th>
                            <th scope="col">信息来源</th>
                            <th scope="col">接收者</th>
                            <th scope="col">创建时间</th>
                            <th scope="col">信息类型</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="object in records" :key="object.id">
                            <td>{{ object.id }}</td>
                            <td>{{ object.courseName }}</td>
                            <td>{{ object.content }}</td>
                            <td>{{ object.sender }}</td>
                            <td>{{ object.receiver }}</td>
                            <td>{{ object.createdTime }}</td>
                            <td>
                                <span v-if="object.type === 0">
                                    通知
                                </span>
                                <span v-else>
                                    作业文件
                                </span>
                            </td>
                            <td>
                                <!-- 信息源头是自己的通知才允许修改和删除 -->
                                <div style="text-align:end;">
                                    <button v-if="object.type === 0" type="button"
                                        :class="object.sender === $store.state.user.name ? 'btn btn-success' : 'btn btn-success disabled'"
                                        data-bs-toggle="modal"
                                        :data-bs-target="`#update-info-modal-` + object.id">修改</button>
                                    <!-- 作业文件是下载,通知文件才修改 -->
                                    <button v-else type="button" class="btn btn-info" data-bs-toggle="modal"
                                        :data-bs-target="`#download-info-modal-` + object.id">下载</button>

                                    <button type="button"
                                        :class="object.sender === $store.state.user.name ? 'btn btn-danger' : 'btn btn-danger disabled'"
                                        data-bs-toggle="modal" :data-bs-target="`#delete-info-modal-` + object.id"
                                        style="margin-left: 15px;">删除</button>
                                </div>
                                <!-- 删除model -->
                                <div class="modal fade" data-bs-backdrop="static" :id="`delete-info-modal-` + object.id"
                                    tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="delete">删除教学大纲</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                确定删除记录{{ object.id }}?
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    @click="removeOneInfo(object)">确定</button>
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">取消</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 修改成功提示 -->
                                <div class="modal fade" id="update-success" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-success" role="alert">
                                                修改成功!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 修改失败提示 -->
                                <div class="modal fade" id="update-error" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-danger" role="alert">
                                                {{ addInfo.error_message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 删除成功提示 -->
                                <div class="modal fade" id="delete-success" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-success" role="alert">
                                                删除成功!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 下载Modal -->
                                <div class="modal fade" data-bs-backdrop="static" :id="`download-info-modal-` + object.id"
                                    tabindex="-1">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-6">下载作业</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="mb-3" style="color: red;">
                                                    确定要下载该作业吗?
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    @click="downloadFile(object)">确定</button>
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">取消</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 修改Modal -->
                                <div class="modal fade" data-bs-backdrop="static" :id="`update-info-modal-` + object.id"
                                    tabindex="-1">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-6">修改自己上传的通知/作业</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close" @click="refreshAddInfo()"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="mb-3">
                                                    <label for="courseName" class="form-label">所属课程</label>
                                                    <select v-model="object.courseName" class="form-select">
                                                        <option value="">请选择所属课程</option>
                                                        <option v-for="course in addInfo.plans" :key="course.id"
                                                            :value="course.courseName">{{
                                                                course.courseName }}</option>
                                                    </select>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="content" class="form-label">内容</label>
                                                    <textarea placeholder="请输入内容" class="form-control" id="trainingGoal"
                                                        rows="8" v-model="object.content" maxlength="1000"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="className" class="form-label">接收方</label>
                                                    <select v-model="object.receiver" class="form-select">
                                                        <option value="" selected>请选择接收方</option>
                                                        <option v-for=" receiver in addInfo.receivers" :key="receiver.id"
                                                            :value="receiver.className === undefined ? receiver.name : receiver.className">

                                                            <span v-if="receiver.className === undefined">{{ receiver.name
                                                            }}</span>
                                                            <span v-else>{{ receiver.className }}</span>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="type" class="form-label fs-6">信息类型</label>
                                                    <!-- 使用事件对象（event object）来获取当前触发事件的select元素的id -->
                                                    <select :id="'type' + object.id" v-model="object.type"
                                                        class="form-select" @change.prevent="show_upload_div($event)">
                                                        <option value="0" selected>通知</option>
                                                        <option value="1">作业文件</option>
                                                    </select>
                                                </div>

                                                <!-- 默认隐藏当信息类型为作业时显示 -->
                                                <div class="mb-3" style="display: none;"
                                                    :id="'uploadDiv' + 'type' + object.id">
                                                    <div for="formFile" class="form-label">上传作业</div>
                                                        <input :id="'fileInput'+object.id" type="file" name="file" accept=".zip,.rar" />
                                                        <button type="submit" @click.prevent="upload(object.id)" class="btn btn-info">上传</button>
                                                        <div style="color: red;">{{ addInfo.error_message }}</div> 
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    @click="updateOneInfo(object)">确定</button>
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal" @click="refreshAddInfo()">取消</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li :class="'page-item ' + (current_page === 1 ? 'disabled' : '')">
                            <a class="page-link" @click="to_page(-2)" href="#">前一页</a>
                        </li>
                        <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number">
                            <a class="page-link" href="#" @click="to_page(page.number)">{{ page.number }}</a>
                        </li>
                        <li :class="'page-item ' + (current_page === max_pages ? 'disabled' : '')">

                            <a class="page-link" href="#" @click="to_page(-1)">后一页</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <!-- 下载成功提示 -->
    <div class="modal fade" id="download-success" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    下载成功!
                </div>
            </div>
        </div>
    </div>
    <!-- 作业上传成功提示 -->
    <div class="modal fade" id="upload-success" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    作业上传成功!
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { ref, reactive } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap'

export default {
    setup() {
        const store = useStore();

        // 列表信息
        let records = ref([]);
        let creatorId = ref('');

        // 分页信息
        let current_page = ref(''); // 当前页
        let total_records = 0; // 总记录数
        let pages = ref([]); // 页面按钮显示情况 前几页是否存在 后几页页是否存在
        let max_pages = ref('');

        //添加框所用到的信息
        const addInfo = reactive({
            id: "",
            courseName: "",
            type: "",
            content: "",
            createdTime: "",
            receiver: "",
            error_message: "",
            url: "", // 文件上传的url
            plans: [], // 供选择的教学方案列表
            receivers: [],
        })

        const getAllInfo = (page) => {
            current_page.value = page;
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/getAllInfo",
                type: "get",
                data: {
                    page,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(resp);
                    records.value = resp.records;

                    addInfo.plans = resp.plans;
                    // 如果我是老师,信息的接收方只能是学生或班级
                    if (store.state.user.role == 1) {
                        addInfo.receivers = resp.classes; // 把班级信息放到接收方
                        addInfo.receivers = addInfo.receivers.concat(resp.students); // 把学生信息放到接收方
                    } else if (store.state.user.role == 0) { // 如果我是学生信息的接收方只能选老师
                        addInfo.receivers = resp.teachers;
                    }
                    total_records = resp.records_count;
                    max_pages.value = parseInt(Math.ceil(total_records / 8)); // 实时更新页面的最大值
                    update_pages();
                },
            })
        };
        getAllInfo(1);


        // 更新页面显示按钮
        const update_pages = () => {
            let new_pages = []; // 更新按钮数
            for (let i = current_page.value - 2; i <= current_page.value + 2; i++) {
                if (i >= 1 && i <= max_pages.value) {
                    new_pages.push({
                        number: i,  // 页面编号
                        is_active: i === current_page.value ? "active" : "",   // 是否被激活
                    }); // 最多将当前页的前两页和后两页显示
                }
            }

            pages.value = new_pages;
        }
        // 展示提示信息
        const show_modal = (id) => {
            var myModal = new Modal(document.getElementById(id));
            myModal.show();
            setTimeout(() => {
                myModal.hide();
            }, 1000);
        }

        // 跳转页面

        const to_page = (page) => {
            if (page === -2) page = current_page.value - 1;
            else if (page === -1) page = current_page.value + 1;
            if (page >= 1 && page <= max_pages.value) {
                getAllInfo(page)
            }
        }

        // 单击作业显示 单击信息关闭
        const show_upload_div = (event) => {
            // 获得触发事件的标签id
            const id = event.target.id;
            let uploadDiv;
            if (id == "type") {
                uploadDiv = document.getElementById("uploadDiv");
            } else {
                console.log("uploadDiv" + id);
                uploadDiv = document.getElementById("uploadDiv" + id);
            }
            // 通过id往下找,查找符合条件的第一个对象 如果被选中的option的值为1说明点了作业 显示
            const infoType = document.querySelector("#" + id + " option:checked").value;
            if (infoType === "1") {

                uploadDiv.style.display = "block";
            } else {
                uploadDiv.style.display = "none";
            }
        }
        const upload = (id) => {
            // 1.获得文件的input标签
            let fileInput = document.getElementById("fileInput");
            if(id!=0) fileInput = document.getElementById("fileInput" + id);

            addInfo.error_message = "";
            // 2.获得文件的对象
            const file = fileInput.files[0];
            if (!file) {
                addInfo.error_message = "请选择一个文件";
                return;
            }

            // 3.将文件对象转化为可以传输的formData
            const formData = new FormData();
            formData.append('file', file);
            // 4.传文件给后端
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/upload",
                type: "post",
                data: formData,
                processData: false, // 必须禁用JQuery处理数据
                contentType: false, // 必须禁用JQuery设置content-type
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    addInfo.error_message = "";
                    if (resp.error_message === "success") {
                        show_modal('upload-success');
                        addInfo.url = resp.path; // 更新url
                    } else {
                        addInfo.error_message = resp.error_message;
                    }
                }, error() {
                    // console.log(resp);
                }
            });
        }
        const addOneInfo = () => {
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/addOneInfo",
                type: "post",
                data: {
                    sender: store.state.user.name,
                    receiver: addInfo.receiver,
                    courseName: addInfo.courseName,
                    content: addInfo.content,
                    type: addInfo.type,
                    url: addInfo.url,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开添加失败模态框
                        show_modal('add-error');
                    } else {
                        Modal.getInstance("#add-info-bnt").hide();
                        // 打开添加成功模态框
                        show_modal('add-success');
                    }
                    refreshAddInfo();
                    getAllInfo(1);
                    
                },
            })
        };
        const removeOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/deleteOne",
                type: "post",
                data: {
                    id: object.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭删除模态框
                    Modal.getInstance(`#delete-info-modal-` + object.id).hide();
                    if (resp.error_message === "success") {
                        // 成功提示删除成功
                        show_modal('delete-success');
                    }
                    getAllInfo(1);
                }
            })
        };

        const downloadFile = (object) => {
            // 下载文件:浏览器地址为要下载文件url并按下回车
            const url = object.url; // 您要跳转的 文件URL
            window.location.href = url; // 跳转到指定链接进行下载
            //关闭确认下载模态框 
            Modal.getInstance(`#download-info-modal-` + object.id).hide();
            show_modal('download-success');
        };

        const updateOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/user/notice/updateOne",
                type: "post",
                data: {
                    id: object.id,
                    sender: store.state.user.name,
                    receiver: object.receiver,
                    courseName: object.courseName,
                    content: object.content,
                    type: object.type,
                    url: object.url,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭修改模态框
                    Modal.getInstance(`#update-info-modal-` + object.id).hide();
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开修改失败模态框
                        show_modal('update-error');
                    } else {
                        // 打开修改成功模态框
                        show_modal('update-success')
                    }
                    getAllInfo(1);
                },
            })
        };
        const refreshAddInfo = () => {
            addInfo.receiver = "";
            addInfo.courseName = "";
            addInfo.content = "";
            addInfo.type = "";
            addInfo.url = "";
            addInfo.error_message ="";
            getAllInfo(1);
        };
        return {
            records,
            creatorId,
            addInfo,
            current_page,
            total_records,
            pages,
            max_pages,
            downloadFile,
            upload,
            addOneInfo,
            removeOneInfo,
            updateOneInfo,
            refreshAddInfo,
            update_pages,
            to_page,
            show_modal,
            show_upload_div,
        }
    }
}


</script>
    
<style scoped></style>