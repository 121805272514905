import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/404/NotFound'
import LoginView from '../views/user/account/LoginView'
import MySpaceView from '../views/user/MySpaceView'
import CourseWareView from '../views/teachingmanagement/CourseWareView'
import HomeWorkView from '../views/teachingmanagement/HomeWorkView'
import TeachingPlanView from '../views/teachingmanagement/TeachingPlanView'
import TeachingProgrammeView from '../views/teachingmanagement/TeachingProgrammeView'
import TrainingProgramView from '../views/teachingmanagement/TrainingProgramView'
import RegisterView from '../views/user/account/RegisterView'
import HomeView from '../views/user/account/HomeView'
import store from '../store/index'

const routes = [
  {   
     path:"/",
     name:"Home",
     redirect:"/user/account/home",
    //  额外信息
     meta:{
       requestAuth: false,
     }
  },
  {
    name: "training_index",
    path: "/teaching/trainingPlan",
    component: TrainingProgramView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "programme_index",
    path: "/teaching/teachingprogramme",
    component: TeachingProgrammeView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "plan_index",
    path: "/teaching/plan",
    component: TeachingPlanView,
    meta:{
      requestAuth: true,
    }

  },
  {
    name: "courseware_index",
    path: "/teaching/courseware",
    component: CourseWareView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "login_index",
    path: "/user/account/login",
    component: LoginView,
    meta:{
      requestAuth: false,
    }
  },
  {
    name: "user_account_register",
    path: "/user/account/register",
    component:RegisterView,
    meta:{
      requestAuth: false,
    }
  },
  {
    name: "myspace_index",
    path: "/user/account/myspace",
    component: MySpaceView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "home_index",
    path: "/user/account/home",
    component: HomeView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "homework_index",
    path: "/teaching/homework",
    component: HomeWorkView,
    meta:{
      requestAuth: true,
    }
  },
  {
    name: "404",
    path: "/404",
    component: NotFound,
    meta:{
      requestAuth: false,
    }
  },
  {
    path:"/:catchAll(.*)",
    redirect:"/404"
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to,from,next) =>{

    let local_has_token = true;
    const jwt_token = localStorage.getItem("jwt_token"); // 将token从浏览器中的内存取出

    // 每次路由请求前检查本地是否有token

    if(jwt_token){
      store.commit("updateToken",jwt_token);
      store.dispatch("getinfo",{
        success(){   
        },
        error(){                      // 有token但过期了 重新获取
          router.push({name:"login_index"})
        }
      })
    }else{
      local_has_token = false;
    }

    // 如果访问授权页面且状态为未登录
    if(to.meta.requestAuth && !store.state.user.is_login){ 
      if(local_has_token){
        next(); // // 如果本地有token 跳转到对应页面
      } else router.push({name:"login_index"});  // 否则跳转到登录页面
    }else{  // 访问不授权页面 或访问授权页面状态为登录 next
      next();
    }
})

export default router
