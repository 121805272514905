<template>
    <div class="container">
        <div class="card" style="margin-top: 20px;">
            <div class="card-header">
                <span style="font-size: xx-large;">培养方案管理</span>
                <button class="btn btn-primary float-end" type="button" data-bs-toggle="modal"
                    data-bs-target="#add-info-bnt">添加</button>
            </div>

            <!-- 添加Modal -->
            <div class="modal fade" data-bs-backdrop="static" id="add-info-bnt" tabindex="-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-6">添加培养方案</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="refreshAddInfo"></button>

                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="className" class="form-label">适用班级</label>
                                <select v-model="addInfo.className" class="form-select">
                                    <option value="" selected>请选择班级</option>
                                    <option v-for=" theClass in addInfo.classes" :key="theClass.id"
                                        :value="theClass.className">{{ theClass.className }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="trainingGoal" class="form-label">培养目标</label>
                                <textarea placeholder="请输入培养目标" class="form-control" id="trainingGoal" rows="3"
                                    v-model="addInfo.trainingGoal"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="courseSetting" class="form-label">课程设置</label>
                                <textarea placeholder="请输入课程设置" class="form-control" id="courseSetting"
                                    v-model="addInfo.courseSetting" rows="8"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="practicalWork" class="form-label">实践环节</label>
                                <textarea placeholder="请输入实践环节" class="form-control" id="practicalWork"
                                    v-model="addInfo.practicalWork" rows="9"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="addOneInfo">确定</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                @click="refreshAddInfo">取消</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 添加成功提示 -->
            <div class="modal fade" id="add-success" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-body">
                        <div class="alert alert-success" role="alert">
                            添加成功!
                        </div>
                    </div>
                </div>
            </div>
            <!-- 添加失败提示 -->
            <div class="modal fade" id="add-error" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert">
                            {{ addInfo.error_message }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">创建者</th>
                            <th scope="col">适用班级</th>
                            <th scope="col">培养目标</th>
                            <th scope="col">课程设置</th>
                            <th scope="col">实践环节</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="object in records" :key="object.record.id">
                            <td>{{ object.record.id }}</td>
                            <td>{{ object.creator }}</td>
                            <td>{{ object.record.className }}</td>
                            <td>{{ object.record.trainingGoal }}</td>
                            <td>{{ object.record.courseSetting }}</td>
                            <td>{{ object.record.practicalWork }}</td>
                            <td>
                                <div style="text-align:end;">
                                    <button type="button" class="btn btn-success" data-bs-toggle="modal"
                                        :data-bs-target="`#update-info-modal-` + object.record.id">修改</button>
                                    <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                        :data-bs-target="`#delete-info-modal-` + object.record.id"
                                        style="margin-left: 15px;">删除</button>
                                </div>
                                <!-- 删除model -->
                                <div class="modal fade" data-bs-backdrop="static"
                                    :id="`delete-info-modal-` + object.record.id" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="delete">删除培养方案</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                确定删除记录{{ object.record.id }}?
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    @click="removeOneInfo(object.record)">确定</button>
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">取消</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 修改成功提示 -->
                                <div class="modal fade" id="update-success" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-success" role="alert">
                                                修改成功!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 修改失败提示 -->
                                <div class="modal fade" id="update-error" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-danger" role="alert">
                                                {{ addInfo.error_message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 删除成功提示 -->
                                <div class="modal fade" id="delete-success" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-body">
                                            <div class="alert alert-success" role="alert">
                                                删除成功!
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 修改Modal -->
                                <div class="modal fade" data-bs-backdrop="static"
                                    :id="`update-info-modal-` + object.record.id" tabindex="-1">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-6">修改培养方案</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="mb-3">
                                                    <label for="className" class="form-label">适用班级</label>
                                                    <select v-model="object.record.className" class="form-select">
                                                        <option v-for="theClass in addInfo.classes" :key="theClass.id"
                                                            :value="theClass.className">{{ theClass.className }}</option>
                                                    </select>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="trainingGoal" class="form-label">培养目标</label>
                                                    <textarea placeholder="请输入培养目标" class="form-control" id="trainingGoal"
                                                        rows="3" v-model="object.record.trainingGoal"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="courseSetting" class="form-label">课程设置</label>
                                                    <textarea placeholder="请输入课程设置" class="form-control" id="courseSetting"
                                                        v-model="object.record.courseSetting" rows="8"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="practicalWork" class="form-label">实践环节</label>
                                                    <textarea placeholder="请输入实践环节" class="form-control" id="practicalWork"
                                                        v-model="object.record.practicalWork" rows="9"></textarea>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    @click="updateOneInfo(object.record)">确定</button>
                                                <button type="button" class="btn btn-secondary"
                                                    data-bs-dismiss="modal">取消</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li :class="'page-item ' + (current_page === 1 ? 'disabled' : '')">
                            <a class="page-link" @click="to_page(-2)" href="#">前一页</a>
                        </li>
                        <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number">
                            <a class="page-link" href="#" @click="to_page(page.number)">{{ page.number }}</a>
                        </li>
                        <li :class="'page-item ' + (current_page === max_pages ? 'disabled' : '')">

                            <a class="page-link" href="#" @click="to_page(-1)">后一页</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>
    
<script>
import { ref, reactive } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap'

export default {
    setup() {
        const store = useStore();

        // 列表信息
        let records = ref([]);
        let creatorId = ref('');

        // 分页信息
        let current_page = ref(''); // 当前页
        let total_records = 0; // 总记录数
        let pages = ref([]); // 页面按钮显示情况 前几页是否存在 后几页页是否存在
        let max_pages = ref('')
        //添加框所用到的信息
        const addInfo = reactive({
            id: "",
            className: "", // 班级名
            trainingGoal: "", // 培养目标
            courseSetting: "", // 课程设置
            practicalWork: "", // 事件环节
            error_message: "", // 提示信息
            classes: [], // 供选择的班级列表
        })

        const getAllInfo = (page) => {
            current_page.value = page;
            $.ajax({
                url: "https://erikblog.cn/api/teaching/trainingPlan/getAllInfo",
                type: "get",
                data: {
                    page,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    records.value = resp.records;
                    total_records = resp.records_count;
                    max_pages.value = parseInt(Math.ceil(total_records / 8)); // 实时更新页面的最大值
                    addInfo.classes = resp.classes;
                    update_pages();
                },
            })
        };
        getAllInfo(1);


        // 更新页面显示按钮
        const update_pages = () => {
            let new_pages = []; // 更新按钮数
            for (let i = current_page.value - 2; i <= current_page.value + 2; i++) {
                if (i >= 1 && i <= max_pages.value) {
                    new_pages.push({
                        number: i,  // 页面编号
                        is_active: i === current_page.value ? "active" : "",   // 是否被激活
                    }); // 最多将当前页的前两页和后两页显示
                }
            }

            pages.value = new_pages;
        }
        // 跳转页面

        const to_page = (page) => {
            if (page === -2) page = current_page.value - 1;
            else if (page === -1) page = current_page.value + 1;
            if (page >= 1 && page <= max_pages.value) {
                getAllInfo(page)
            }
        }

        const addOneInfo = () => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/trainingPlan/addOneInfo",
                type: "post",
                data: {
                    className: addInfo.className,
                    trainingGoal: addInfo.trainingGoal,
                    courseSetting: addInfo.courseSetting,
                    practicalWork: addInfo.practicalWork,
                    creatorId: store.state.user.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开添加失败模态框
                        var myModal = new Modal(document.getElementById('add-error'));
                        myModal.show();
                        //定时关闭
                        setTimeout(function () {
                            myModal.hide();
                        }, 1000)
                    } else {
                        Modal.getInstance("#add-info-bnt").hide();
                        // 打开添加成功模态框
                        var myModals = new Modal(document.getElementById('add-success'));
                        myModals.show();
                        //定时关闭
                        setTimeout(function () {
                            myModals.hide();
                        }, 1000)
                    }
                    getAllInfo(1);
                    addInfo.className = "";
                    addInfo.trainingGoal = "";
                    addInfo.courseSetting = "";
                    addInfo.practicalWork = "";

                },
            })
        };
        const removeOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/trainingPlan/deleteOne",
                type: "post",
                data: {
                    id: object.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭删除模态框
                    Modal.getInstance(`#delete-info-modal-` + object.id).hide();
                    if (resp.error_message === "success") {
                        // 成功提示删除成功
                        var myModals = new Modal(document.getElementById('delete-success'));
                        myModals.show();
                        //定时关闭
                        setTimeout(function () {
                            myModals.hide();
                        }, 1000)
                    }
                    getAllInfo(1);
                }
            })
        };
        const updateOneInfo = (object) => {
            $.ajax({
                url: "https://erikblog.cn/api/teaching/trainingPlan/updateOne",
                type: "post",
                data: {
                    id: object.id,
                    className: object.className,
                    trainingGoal: object.trainingGoal,
                    courseSetting: object.courseSetting,
                    practicalWork: object.practicalWork,
                    creatorId: store.state.user.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // 关闭修改模态框
                    Modal.getInstance(`#update-info-modal-` + object.id).hide();
                    if (resp.error_message !== "success") {
                        addInfo.error_message = resp.error_message;
                        // 打开修改失败模态框
                        var myModal = new Modal(document.getElementById('update-error'));
                        myModal.show();
                        //定时关闭
                        setTimeout(function () {
                            myModal.hide();
                        }, 2000);
                    } else {
                        // 打开修改成功模态框
                        var myModals = new Modal(document.getElementById('update-success'));
                        myModals.show();
                        //定时关闭
                        setTimeout(function () {
                            myModals.hide();
                        }, 1000)
                    }
                    getAllInfo(1);
                },
            })
        };
        const refreshAddInfo = () => {
            addInfo.className = "";
            addInfo.trainingGoal = "";
            addInfo.courseSetting = "";
            addInfo.practicalWork = "";
            addInfo.error_message = "";
            addInfo.id = "";
        };
        return {
            records,
            creatorId,
            addInfo,
            current_page,
            total_records,
            pages,
            max_pages,
            addOneInfo,
            removeOneInfo,
            updateOneInfo,
            refreshAddInfo,
            update_pages,
            to_page,
        }
    }
}


</script>
    
<style scoped></style>